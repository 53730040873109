import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Checkbox from '../../elements/form-input-checkbox';

const CellOrderActions = inject("stores") (
    observer (
        class CellOrderActions extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    nr: 0,
                    items: [],
                    formControls: {
                        discounts: {
                            value: this.storeOrders.customDiscounts,
                            touched: false
                        },
                        shipping: {
                            value: this.storeOrders.customShipping,
                            touched: false
                        },
                        noShipping: {
                            value: this.storeOrders.noShipping,
                            touched: false
                        }
                    }
                }

                
            }

            componentDidMount = async() => {
                const custom = await this.storeOrders.getOverruled(this.props.order_id);
                
                const updatedControls = this.initForm(this.state.formControls);

                this.setState({
                    formControls: updatedControls,
                }); 
            }

            componentDidUpdate = (prevProps) => {
                
            }

            initForm(formControls) {
                const updatedControls = {
                    ...formControls
                };
        
                Object.keys(formControls).map((key, i) => {
                    const updatedFormElement = {
                        ...updatedControls[key]
                    };
        
                    switch(key) {
                        case 'discounts':
                            updatedFormElement.value = this.storeOrders.customDiscounts;  
                            break;
                        case 'shipping':
                            if (parseFloat(this.storeOrders.customShipping) > 0) {
                                updatedFormElement.value = 1;
                            } else {
                                updatedFormElement.value = 0;
                            }
                            break;
                        case 'noShipping':
                            updatedFormElement.value = this.storeOrders.noShipping;  
                            break;
                        default:   
                    }
  
                    updatedControls[key] = updatedFormElement;
                });
                
                return updatedControls;
            }

            handleCheckboxChange = (name) => {
                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...this.state.formControls[name],
                            value: !this.state.formControls[name].value,
                            selected: !this.state.formControls[name].value ? 1 : 0
                        }
                    }
                }, (e) => this.setOverruled(name))
            }

            setOverruled = async(name) => {
                const orderId = this.props.order_id;
                const key = name;
                const value = this.state.formControls[name].value;

                const overruled = await this.storeOrders.setOverruled(orderId, key, value);
                const custom = await this.storeOrders.getOverruled(orderId);
            }

            render() {
                return (   
                    <React.Fragment>
                    <span className="table--orders__cell-value">
                        <Checkbox 
                            name = {"discounts"} 
                            label = {'Overrule kortingen'}
                            value = {this.state.formControls["discounts"].value}
                            isSelected = {this.state.formControls["discounts"].value}
                            onCheckboxChange={() => this.handleCheckboxChange('discounts')}
                            className = {"--reduced"}
                        />
                        
                    </span>
                    <span className="table--orders__cell-value">
                        <Checkbox 
                            name = {"shipping"} 
                            label = {'Overrule verzendkosten'}
                            value = {this.state.formControls["shipping"].value}
                            isSelected = {this.state.formControls["shipping"].value}
                            onCheckboxChange={() => this.handleCheckboxChange('shipping')}
                            className = {"--reduced"}
                        />
                    </span>
                    <span className="table--orders__cell-value">
                        <Checkbox 
                            name = {"noShipping"} 
                            label = {'Geen verzendkosten'}
                            value = {this.state.formControls["noShipping"].value}
                            isSelected = {this.state.formControls["noShipping"].value}
                            onCheckboxChange={() => this.handleCheckboxChange('noShipping')}
                            className = {"--reduced"}
                        />
                    </span>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellOrderActions));
