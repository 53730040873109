import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Checkbox from '../../elements/form-input-checkbox';
import TextInput from '../../elements/form-input-text';
import CellOrderSummaryItem from './cell-order-summary-item';

// const CellOrderTitle = inject("stores") (
//     observer (
//         class CellOrderTitle extends Component {

//             constructor(props) {
//                 super(props);
//                 this.props = props;  
                
//                 this.state = {
//                     nr: 0,
//                     items: [],
//                     formControls: {
//                         after_delivery: {
//                             value: (this.props.after_delivery !== null && this.props.after_delivery !== 'undefined' && this.props.after_delivery !== undefined) ? 1 : 0,
//                             touched: false
//                         }
//                     },
//                     orderline_id: this.props.orderline_id
//                 }

//                 this.storeUi = this.props.stores.storeUi;
//                 this.storeOrders = this.props.stores.storeOrders;
//             }

//             componentDidMount = () => {
//                 this.setState({
//                     order_fulfilled: this.props.order_fulfilled,
//                     orderline_id: this.props.orderline_id
//                 })
//             }

//             componentDidUpdate = (prevProps) => {
//                 if ((prevProps.after_delivery !== this.props.after_delivery) || 
//                     (prevProps.order_id !== this.props.order_id) || 
//                     (prevProps.order_fulfilled !== this.props.order_fulfilled) ||
//                     (prevProps.orderline_id !== this.props.orderline_id)) {
//                     const name = 'after_delivery';
//                     const value = this.props.after_delivery && this.props.after_delivery.date;

//                     this.setState({
//                         formControls: {
//                             ...this.state.formControls,
//                             [name]: {
//                                 ...this.state.formControls[name],
//                                 value: this.props.after_delivery !== null ? 1 : 0,
//                                 selected: this.props.after_delivery !== null ? 1 : 0
//                             }
//                         }
//                     })

                   
//                 }

//                 if ((prevProps.order_fulfilled !== this.props.order_fulfilled) && this.props.order_fulfilled !== null) {
//                     const name = 'after_delivery';

//                     this.setState({
//                         order_fulfilled: this.props.order_fulfilled,
//                         formControls: {
//                             ...this.state.formControls,
//                             [name]: {
//                                 ...this.state.formControls[name],
//                                 value: 0,
//                                 selected: 0
//                             }
//                         }
//                     }, (e) => this.setAfterDelivery())
//                 }
//             }

//             handleCheckboxChange = (event) => {
//                 const name = 'after_delivery';

//                 this.setState({
//                     formControls: {
//                         ...this.state.formControls,
//                         [name]: {
//                             ...this.state.formControls[name],
//                             value: !this.state.formControls[name].value,
//                             selected: !this.state.formControls[name].value ? 1 : 0
//                         }
//                     }
//                 }, (e) => this.setAfterDelivery())
//             }

//             setAfterDelivery = () => {
//                 if (this.state.formControls['after_delivery'].value == 1) {
//                     this.storeOrders.setAfterDelivery('add', this.props.order_id, this.props.item.orderline_id);
//                 } else {
//                     this.storeOrders.setAfterDelivery('remove', this.props.order_id, this.props.item.orderline_id);
//                 }
//             }

//             render() {
//                 return (   
//                     <span className="table--orders__cell-value">
//                         {this.props.order_fulfilled !== null
//                         ?   <React.Fragment>
//                                 {this.props.item.description}<br/>
//                                 <span
//                                      className="table--orders__cell-value--isbn"
//                                 >
//                                     {this.props.item.sku}
//                                 </span>
//                             </React.Fragment>
//                         :   <React.Fragment>
//                             <Checkbox 
//                                 name = {"after_delivery"} 
//                                 label = {this.props.item.description}
//                                 value = {this.state.formControls["after_delivery"].value}
//                                 isSelected = {this.state.formControls["after_delivery"].value}
//                                 onCheckboxChange={this.handleCheckboxChange}
//                                 className = {"--reduced"}
//                             />
//                             <span
//                                 className="table--orders__cell-value--isbn --spaced"
//                             >
//                                 {this.props.item.sku}
//                             </span>
//                             </React.Fragment>
//                         }
                        
                        
//                     </span>
//                 )
//             }
//         }
//     )
// )

const CellOrderSummary = inject("stores") (
    observer (
        class CellOrderSummary extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {
                    nr: 0,
                    items: [],
                    editable: false,
                    formControls: {
                        shipping: {
                            value: '',
                            touched: false
                        }
                    },
                }       
            }

            componentDidMount = async() => {
                const items = await this.storeOrders.getOrderItemsData(this.props.order_id);
                this.setState({
                    items: items
                })
            }

            componentDidUpdate = async(prevProps) => {
                const items = await this.storeOrders.getOrderItemsData(this.props.order_id);
                if (prevProps.order_id !== this.props.order_id) {
                    this.setState({
                        items: items
                    })
                }

                if (prevProps.order_fulfilled !== this.props.order_fulfilled) {
                // //     const items = await this.storeOrders.getOrderItemsData(this.props.order_id);
                    this.setState({
                        order_fulfilled: this.props.order_fulfilled,
                        //items: items
                    })
                }

                if (prevProps.noShipping !== this.props.noShipping) {
                    this.handleNoShipping();
                }
            }

            handleChange = (event) => {
                const name = 'shipping';
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            handleNoShipping = () => {
                const name = 'shipping';
                const value = 0;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                }, () => this.saveShipping())
            }

            formatPrice = (price) => {
                let price_formatted = (Math.round(price * 100) / 100).toFixed(2);
                return price_formatted;
            }

            calculatePrice = (item) => {
                let total = 0;
                if (item.discount !== 0 && item.discount !== null) {
                    total = (item.costs * item.amount) * (1 - item.discount/100);
                } else {
                    total = item.costs * item.amount;
                }
                return this.formatPrice(total);
            }

            calculateOrderVAT(perc) {
                let vat_total = 0;
                let vat_perc;
                let vat_shipping = 0;
                
                this.state.items.map((item) => {
                    if (item.vat_perc == perc) {
                        vat_total = vat_total + parseFloat(this.calculatePrice(item)) - parseFloat(this.calculatePrice(item)) / (1 + (perc/100));
                    }
                })
        
                return vat_total.toFixed(2);
            }

            calculateTotalOrder = (vat) => {
                let total = 0;
                this.state.items.map((item) => {
                    total = total + parseFloat(this.calculatePrice(item));
                })
                if (vat === 'excl') {
                    total = total - parseFloat(this.calculateOrderVAT(21)) - parseFloat(this.calculateOrderVAT(9));
                }

                return total.toFixed(2);
            }

            setEditable = () => {
                this.setState({
                    editable: true
                })
            }

            saveShipping = async() => {
                const orderId = this.props.order_id;
                const shipping = this.state.formControls.shipping.value;

                const value = await this.storeOrders.saveCustomShipping(orderId, shipping)
                this.setState({
                    editable: false
                }, () => this.updateOrder())
            }

            updateOrder = async() => {
                const items = await this.storeOrders.getOrderItemsData(this.props.order_id);
                this.setState({
                    items: items
                })
            }

            render() {
                return (                  
                    <React.Fragment>
                        <table className="table--orders-summary">
                            <tr>
                                <th className="table--orders__cell-label">Titel</th>
                                <th className="table--orders__cell-label">Prijs</th>
                                <th className="table--orders__cell-label">Aantal</th>
                                <th className="table--orders__cell-label">Korting</th>
                                <th className="table--orders__cell-label">Totaal</th>
                            </tr>
                        {this.state.items !== null && Array.isArray(this.state.items) && this.state.items.length > 0 && this.state.items.map((item) => (
                            item.description !== 'Verzendkosten' &&
                                <CellOrderSummaryItem
                                    item = {item}
                                    order_id = {this.props.order_id}
                                    orderline_id = {item.orderline_id}
                                    after_delivery = {item.after_delivery}
                                    order_fulfilled = {this.state.order_fulfilled}
                                    updateOrder = {this.updateOrder}
                                />
                        ))}
                        
                        {this.state.items !== null && Array.isArray(this.state.items) && this.state.items.length > 0 && this.state.items.map((item) => (
                            item.description === 'Verzendkosten' &&
                                <tr>
                                    <td><span className="table--orders__cell-label --centered">Verzendkosten</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        {parseInt(this.storeOrders.customShipping) === 1 && parseInt(this.storeOrders.noShipping) === 0
                                        ?   <React.Fragment>
                                                {this.state.editable && 
                                                <span 
                                                    className="table--orders__cell-input"
                                                >
                                                    <TextInput 
                                                        name = {'shipping'} 
                                                        value = {this.state.formControls['shipping'].value}
                                                        onChange = {(e) => this.handleChange(e)}
                                                        touched = {this.state.formControls['shipping'].touched}
                                                    />
                                                </span>
                                                }
                                                {!this.state.editable &&
                                                <span 
                                                    className="table--orders__cell-value"
                                                >
                                                    {this.formatPrice(item.costs)}
                                                </span>
                                                }
                                                {!this.state.editable &&
                                                    <span 
                                                        className="table--orders__cell-action --link"
                                                        onClick={() => this.setEditable()}
                                                    >
                                                        edit
                                                    </span>
                                                }
                                                {this.state.editable &&
                                                    <span 
                                                        className="table--orders__cell-action --link"
                                                        onClick={() => this.saveShipping()}
                                                    >
                                                        save
                                                    </span>
                                                }
                                            </React.Fragment>
                                        :   <span 
                                                className="table--orders__cell-value"
                                            >
                                                {this.formatPrice(item.costs)}
                                            </span>
                                        }
                                    </td>
                                </tr>
                        ))}

                        {this.props.view === 'extended' &&
                            <React.Fragment>
                            <tr>
                                <td>
                                    <span className="table--orders__cell-label --centered --header">Totaal incl. btw</span>
                                </td>
                                <td className="table--orders__cell-value--totals --header">
                                    {this.calculateTotalOrder('incl')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="table--orders__cell-label --centered">Btw (21%)</span>
                                </td>
                                <td className="table--orders__cell-value--totals">
                                    {this.calculateOrderVAT(21)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="table--orders__cell-label --centered">Btw (9%)</span>
                                </td>
                                <td className="table--orders__cell-value--totals">
                                    {this.calculateOrderVAT(9)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="table--orders__cell-label --centered --header">Totaal excl. btw</span>
                                </td>
                                <td className="table--orders__cell-value--totals --header">
                                    {this.calculateTotalOrder('excl')}
                                </td>
                            </tr>
                            </React.Fragment>
                        }
                        </table>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellOrderSummary));
